import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';
import { Loader } from "semantic-ui-react";

function Services() {
  const { firebase } = useContext(FirebaseContext);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
       
        const obtenerServices =  async () => {
          firebase.db.collection("services")
            .onSnapshot(controlarSnapshotServices);
        }
        obtenerServices();
      }, []);
                //====Snapshot permite utilizar la base de datos en tiempo real======
                function controlarSnapshotServices(snapshot) {
                  const services = snapshot.docs.map((doc) => {
                    return {
                      id: doc.id,
                      ...doc.data(),
                    };
                  });
                  //almacenamos los resultados en el state
                  console.log(services);
                  setServices(services);
                  setLoading(false);
                }
  return (
    <>
    {loading ? (
      <Loader active inline="centered">
        Cargando...
      </Loader>
    ) : (
    <section className="module" id="services">
    <div className="container">
      {/* <div className="row">
        <div className="col-sm-6 col-sm-offset-3">
          <h2 className="module-title font-alt">Nuestros servicios</h2>
          <div className="module-subtitle font-serif">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</div>
        </div>
      </div> */}
      <div className="row multi-columns-row">
      {services.map((item, key) => (
        <div key={key} className="col-md-6 col-sm-6 col-xs-12">
          <div className="features-item">
            <div className="features-icon"><span className={item.icon}></span></div>
            <h3 className="features-title font-alt">{item.heading}</h3>
            <p>{item.description}</p>
          </div>
        </div>
        ))}
      </div>
    </div>
  </section>
    )}
    </>
  )
}

export default Services