import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';
import { Loader } from "semantic-ui-react";

function Team() {
    const { firebase } = useContext(FirebaseContext);
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
       
        const obtenerHeader =  async () => {
          firebase.db.collection("team")
            .onSnapshot(controlarSnapshotTeam);
        }
        obtenerHeader();
      }, []);
                //====Snapshot permite utilizar la base de datos en tiempo real======
                function controlarSnapshotTeam(snapshot) {
                  const team = snapshot.docs.map((doc) => {
                    return {
                      id: doc.id,
                      ...doc.data(),
                    };
                  });
                  //almacenamos los resultados en el state
                  setTeam(team);
                  setLoading(false);
                }
  return (
    
    <section className="module" id="team">
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-sm-offset-3">
          <h2 className="module-title font-alt">Nuestro equipo de trabajo</h2>
{/*           <div className="module-subtitle font-serif">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</div>
 */}        </div>
      </div>
      <div className="row content__team">
       
        {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        team.map((item, key) => (
            <div key={key} className="mb-sm-20 wow fadeInUp col-sm-6 col-md-3 card__team">
            <div className="team-item">
            <div className="team-image"><img src={item.imagen} alt="Member Photo"/>
              <div className="team-detail">
                <p className="font-serif">{item.resena}</p>
{/*                 <div className="team-social"><a href="#"><i className="fa fa-facebook"></i></a><a href="#"><i className="fa fa-twitter"></i></a><a href="#"><i className="fa fa-dribbble"></i></a><a href="#"><i className="fa fa-skype"></i></a></div>
 */}              </div>
            </div>
            <div className="team-descr font-alt">
              <div className="team-name">{item.Nombre}</div>
              <div className="team-role">{item.cargo}</div>
            </div>
          </div>
          </div>
        ))
            )}
       
      </div>
    </div>
  </section>
  )
}

export default Team