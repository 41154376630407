import React from "react";
import Home from "./routes/home";
import { Route, Routes } from "react-router";
import firebase, { FirebaseContext } from "./firebase";
import './App.css'
export default function App() {
  return (
    <>
      <FirebaseContext.Provider value={{ firebase }}>
       
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
       
      </FirebaseContext.Provider>
    </>
  );
}
