
//=========PRODUCCIÓN===========
const firebaseConfig = {
    apiKey: "AIzaSyAFJE1opmPrNCkG5b8Wipz3JNkmFdWKvwI",
    authDomain: "sustento-juridico.firebaseapp.com",
    projectId: "sustento-juridico",
    storageBucket: "sustento-juridico.appspot.com",
    messagingSenderId: "403199726852",
    appId: "1:403199726852:web:d04d679f30d1e92e59ac51",
    measurementId: "G-2BEBVT7HVK"
  };
      export default firebaseConfig;