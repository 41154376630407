import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';
import { Loader } from "semantic-ui-react";
import ReactPlayer from 'react-player'


export default function Header() {
    const { firebase } = useContext(FirebaseContext);
    const [header, setHeader] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
       
        const obtenerHeader =  async () => {
          firebase.db.collection("header")
            .onSnapshot(controlarSnapshotHeader);
        }
        obtenerHeader();
      }, []);
                //====Snapshot permite utilizar la base de datos en tiempo real======
                function controlarSnapshotHeader(snapshot) {
                  const header = snapshot.docs.map((doc) => {
                    return {
                      id: doc.id,
                      ...doc.data(),
                    };
                  });
                  //almacenamos los resultados en el state
                  setHeader(header);
                  setLoading(false);
                }
  return (
    <>
     {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <section className="home-section bg-dark-30" id="home" data-background="../assets/images/section-5.jpg">
        {/*  <video class="video-bg"  controls>
          <source src={header[0].video}  type="video/mp4" />
        </video> */}
       
         <ReactPlayer
          url={header[0].video}
          className='video-bg'
          playing={true}
          volume={0}
          controls={true}
          loop={true}
          width='100%'
          height='100%'
          playsinline={true} 
          onContextMenu={(e) => e.preventDefault()}
        /> 
        <div className="titan-caption">
          <div className="caption-content">
          <div className="font-alt mb-40 titan-title-size-4 heading__header">{header[0].heading}</div>
            <div className="font-alt mb-30 titan-title-size-1 subheading__header">{header[0].subheading}</div>           
          </div>
        </div>
      </section>
      )}
  </>
  )
}
