import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';
import { Loader } from "semantic-ui-react";

export default function Nav() {
  const { firebase } = useContext(FirebaseContext);
    const [logo, setLogo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fix, setFix] = useState(false)

    useEffect(() => {
        const obtenerLogo =  async () => {
          firebase.db.collection("logo")
            .onSnapshot(controlarSnapshotLogo);
        }
        obtenerLogo();
      }, [window.scrollY]);
                //====Snapshot permite utilizar la base de datos en tiempo real======
                function controlarSnapshotLogo(snapshot) {
                  const logo = snapshot.docs.map((doc) => {
                    return {
                      id: doc.id,
                      ...doc.data(),
                    };
                  });
                  //almacenamos los resultados en el state
                  setLogo(logo);
                  setLoading(false);
                }
        const setFixed = () =>{
          if(window.scrollY >= 392){
            setFix(true);
          }else{
            setFix(false)
          }
        }
        window.addEventListener("scroll", setFixed);
       
  return (
    <nav className= {fix ? 'navbar navbar-custom navbar-fixed-top fixed':'navbar navbar-custom navbar-fixed-top navbar-transparent'} role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button className="navbar-toggle" 
          type="button" 
          data-toggle="collapse" 
          data-target="#custom-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            </button><a className="navbar-brand">
            {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
              <img src={logo[0].imagen} className='img__logo' />
            )}
              </a>
      </div>
      <div className="collapse navbar-collapse" id="custom-collapse">
        <ul className="nav navbar-nav navbar-right">
        <li><a href="#home">Inicio</a></li>       
        <li><a href="#about">Nosotros</a></li> 
        <li><a href="#services">Servicios</a></li> 
        <li><a href="#team">Nuestro equipo</a></li> 
        <li><a href="#blog">Blog</a></li> 
        </ul>
      </div>
    </div>
  </nav>
  )
}
