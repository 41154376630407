import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';
import { Loader } from "semantic-ui-react";
import Slider from 'infinite-react-carousel';


function Clients() {
  const [isMobile, setIsMobile] = useState(false);
  const { firebase } = useContext(FirebaseContext);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
     
      const obtenerBrands =  async () => {
        firebase.db.collection("brands")
          .onSnapshot(controlarSnapshotBrands);
      }
      obtenerBrands();
    }, []);
              //====Snapshot permite utilizar la base de datos en tiempo real======
              function controlarSnapshotBrands(snapshot) {
                const brands = snapshot.docs.map((doc) => {
                  return {
                    id: doc.id,
                    ...doc.data(),
                  };
                });
                //almacenamos los resultados en el state
                setBrands(brands);
                setLoading(false);
              }
              useEffect(() => {
                // Función para verificar el ancho de la ventana y determinar si es un dispositivo móvil
                function checkIsMobile() {
                  if (window.innerWidth <= 768) { // Puedes ajustar el valor según tus necesidades
                    setIsMobile(true);
                  } else {
                    setIsMobile(false);
                  }
                }
            
                // Verificar el ancho de la ventana cuando se carga la página
                checkIsMobile();
            
                // Agregar un listener de cambio de tamaño de ventana para actualizar el estado
                window.addEventListener('resize', checkIsMobile);
            
                // Limpieza del listener cuando el componente se desmonta
                return () => {
                  window.removeEventListener('resize', checkIsMobile);
                };
              }, []);
  return (
    <section className="module" id="clients">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <h2 className="module-title font-alt">Algunos de nuestros clientes</h2>
{/*               <div className="module-subtitle font-serif">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</div>
 */}            </div>
          </div>
            {loading ? (
              <Loader active inline="centered">
                Cargando...
              </Loader>
            ) : (
                  <Slider slidesToShow={isMobile ? 1 : 8} centerMode={true} autoplay={true}>
                    {brands.map((item, key) => (
                    <div key={key} className='content-brands'>                  
                      <img src={item.icon} alt='imagem' title='imagem' />
                      <span>{item.name}</span>
                    </div>

                  ))}
              </Slider>
            
            )}
       
        </div>
    </section>
  )
}

export default Clients