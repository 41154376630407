import React, {useEffect, useState, useContext} from 'react';
import { FirebaseContext } from '../firebase';
import { Loader } from "semantic-ui-react";

function About() {
    const { firebase } = useContext(FirebaseContext);
    const [about, setAbout] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
       
        const obtenerHeader =  async () => {
          firebase.db.collection("about")
            .onSnapshot(controlarSnapshotAbout);
        }
        obtenerHeader();
      }, []);
                //====Snapshot permite utilizar la base de datos en tiempo real======
                function controlarSnapshotAbout(snapshot) {
                  const about = snapshot.docs.map((doc) => {
                    return {
                      id: doc.id,
                      ...doc.data(),
                    };
                  });
                  //almacenamos los resultados en el state
                  setAbout(about);
                  setLoading(false);
                }
  return (
   <>
    {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
       <>
        <section className="module" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-sm-offset-1">
                            <h2 className="module-title font-alt">{about[0].heading}</h2>
                            <div className="module-subtitle font-serif large-text">{about[0].subheading}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 col-sm-offset-5">
                            <div className="large-text align-center"><a className="section-scroll" href="#services"><i className="fa fa-angle-down"></i></a></div>
                        </div>
                    </div>
                </div>
            </section>
        <hr className="divider-w"/>
       </>
      )
      }
   </>
  )
}

export default About