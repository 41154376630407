import React from 'react'

function Footer() {
  return (
    <>
    <div className="module-small bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <div className="widget">
              <h5 className="widget-title font-alt">Sustento Jurídico</h5>
              <p>Carrera 43B# 16 -80 oficina 204 Edificio Delta</p>
              <p>Tel: +57 5813939</p>Cel: 3104415759
              <p>Email:<a href="#">notificaciones@sustentojuridico.com.co</a></p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="widget">
              <h5 className="widget-title font-alt">Nuestras redes</h5>
              <ul className="icon-list">
                <li> <a target='__blank' href="https://www.instagram.com/sustentojuridicosas/">Instagram</a></li>
                <li> <a target='__blank' href="https://www.facebook.com/sustento.juridico.94">Facebook</a></li>
                <li> <a target='__blank' href="https://twitter.com/sustentojuridi1">Twitter</a></li>
              </ul>
            </div>
          </div>
         
          <div className="col-sm-4">
            <div className="widget">
              <h5 className="widget-title font-alt">Menú principal</h5>
              <ul className="icon-list">
                <li> <a href="#home">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="divider-d"/>
    <footer className="footer bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <p className="copyright font-alt">&copy; 2022&nbsp;<a href="">Apptomatic</a>, All Rights Reserved</p>
          </div>
          <div className="col-sm-6">
            <div className="footer-social-links"><a href="#"><i className="fa fa-facebook"></i></a><a href="#"><i className="fa fa-twitter"></i></a><a href="#"><i className="fa fa-dribbble"></i></a><a href="#"><i className="fa fa-skype"></i></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer