import React from "react";
import About from "../components/about";
import Footer from "../components/footer";
import Header from "../components/header";
import Nav from "../components/nav";
import Services from "../components/services";
import Team from "../components/team";
import Clients from "../components/clients";
import Blog from "../components/blog";


export default function Home() {
  return (
    <>
     <main>
       {/*  <div class="page-loader">
          <div class="loader">Loading...</div>
        </div> */}
        <Nav/>
        <Header/>
        <div className="main">
       <About/>
       <Services/>
       <Team/>
       {/* <Blog/> */}
       <Clients/>
       <Footer/>
        </div>
      </main>
       
    </>
  );
}
